import React from 'react';

import Labels from '~/src/common/components/GridCard/ProductCard/AvailableProductCard/Labels';
import Icon from '~/src/common/components/Icon';
import Link from '~/src/common/components/Link';
import Rating from '~/src/common/components/Rating';
import dayjs from '~/src/common/services/Date';
import I18n from '~/src/common/services/I18n';
import { AddToCartProductEventSource } from '~/src/common/services/Tracker';
import { ImageFormatIds, ImageParams } from '~/src/common/typings/image';
import { getImageUrlFromFormat } from '~/src/common/utils/cloudinary';
import {
  formatBatchDiscountLabel,
  formatPromoLabel,
  getPricePerWeight,
} from '~/src/common/utils/prices';
import {
  getProductPromoWithBulkDiscount,
  SPECIFIC_TAG_ATTRIBUTE_KEY,
} from '~/src/common/utils/product';
import { useProductLink } from '~/src/screens/CatalogContent/ContentScreen/hooks';
import MyProductsCardButton from '~/src/screens/MyProducts/components/Buttons/MyProductsCardButton';
import { AllowedProductForCard } from '~/src/typings/products/types';

import * as GS from '../../layout';
import * as CS from '../layout';

import * as S from './layout';
import PickerSection from './PickerSection';

interface Props {
  product: AllowedProductForCard;
  categoryName?: string;
  subcategoryName?: string;
  isDeliverySetup: boolean;
  cartQuantity: number;
  eventSource: AddToCartProductEventSource;
  onDisabledPickerClick: (quantity: number, increment: number) => void;
  freeQuantity: number;
}

const AvailableProductCard = ({
  product,
  isDeliverySetup,
  cartQuantity,
  categoryName,
  subcategoryName,
  eventSource,
  onDisabledPickerClick,
  freeQuantity,
}: Props) => {
  const {
    itemPrice,
    name,
    origin,
    weightPrice,
    consumptionDate,
    images,
    labels,
    attributes,
    rating,
    hideWeightPrice,
  } = product;

  const promo = getProductPromoWithBulkDiscount(product);

  const imageUrl = getImageUrlFromFormat(
    images[0],
    ImageFormatIds.CardRatio,
    ImageParams.listingCard,
  );

  const { as, query } = useProductLink(product.slug, {
    category: categoryName,
    subcategory: subcategoryName,
    eventSource,
  });

  const hasPromo = promo != null;
  const isDestock = hasPromo && promo.type === 'DESTOCK';
  const isBatchDiscount = hasPromo && promo.mechanism === 'BATCH_DISCOUNT';

  const pricePerWeight = weightPrice ? getPricePerWeight(weightPrice) : '';

  const customTag = attributes.find(({ key }) => key === SPECIFIC_TAG_ATTRIBUTE_KEY)?.value as
    | string
    | undefined;

  const isCustomTagDisplayed = customTag != null && !hasPromo;

  const isConsumptionDateDisplayed =
    consumptionDate != null && dayjs(consumptionDate).isBefore(dayjs().add(2, 'months'));

  const isPricePerWeightDisplayed = pricePerWeight && !hideWeightPrice;

  const nbLabels = labels.length;

  // Ajout de '...' si le nom de l'origine est trop long. Cela dépend de l'espace disponible et donc des labels affichés
  const maxOriginLength = (nbLabels === 1 && 38) || (nbLabels >= 2 && 28) || undefined;
  const isOriginTruncated = origin && maxOriginLength && origin.length > maxOriginLength;

  return (
    <>
      <GS.Image src={imageUrl} $objectFit="cover" $objectPosition="top center" alt={product.name} />

      {hasPromo ? (
        <S.PromoTag
          $backgroundColor={isDestock ? 'DESTOCK' : 'PROMOTION'}
          $color={isDestock ? 'WHITE' : 'BLACK'}>
          {isBatchDiscount
            ? formatBatchDiscountLabel(promo, cartQuantity - freeQuantity)
            : formatPromoLabel(promo, itemPrice)}
        </S.PromoTag>
      ) : null}

      {isCustomTagDisplayed ? <S.CustomTag>{customTag}</S.CustomTag> : null}

      <MyProductsCardButton product={product} />

      <GS.Content>
        <Link href={{ pathname: as, query }} as={as}>
          <CS.TopSectionContainer>
            <GS.InformationContainer>
              <GS.ProductNameContainer>
                <GS.ProductName>{name}</GS.ProductName>
                <Rating rating={rating} />
              </GS.ProductNameContainer>
              {origin != null || nbLabels > 0 ? (
                <S.OriginAndLabelsContainer>
                  {origin != null ? (
                    <S.Origin>
                      <S.OriginFlag origin={origin} />
                      <p>{isOriginTruncated ? `${origin.slice(0, maxOriginLength)}...` : origin}</p>
                    </S.Origin>
                  ) : null}
                  {nbLabels > 0 ? <Labels labels={labels} /> : null}
                </S.OriginAndLabelsContainer>
              ) : null}

              {isPricePerWeightDisplayed || isConsumptionDateDisplayed ? (
                <S.BottomInformationContainer>
                  {isPricePerWeightDisplayed ? <span>{pricePerWeight}</span> : null}
                  {isConsumptionDateDisplayed ? (
                    <span>
                      <Icon
                        name="calendar"
                        size={16}
                        color="WHITE"
                        aria-label={I18n.t('product-card.consumption-date-aria-label')}
                      />
                      <span>{dayjs(consumptionDate).format('DD/MM')}</span>
                    </span>
                  ) : null}
                </S.BottomInformationContainer>
              ) : null}
            </GS.InformationContainer>
          </CS.TopSectionContainer>
        </Link>
        <PickerSection
          product={product}
          isDeliverySetup={isDeliverySetup}
          cartQuantity={cartQuantity}
          onDisabledPickerClick={onDisabledPickerClick}
        />
      </GS.Content>
    </>
  );
};

export default React.memo(AvailableProductCard);
